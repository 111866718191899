// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cms-root-hFv {\n}\n\n.cms-heading-5TQ {\n\n    /* Medium */\n}\n\n.cms-root_empty-2EE,\n.cms-root_1column-8Hs,\n.cms-root_2columnsLeft-YDb,\n.cms-root_2columnsRight-Q-K,\n.cms-root_3columns-yZZ,\n.cms-root_cmsFullWidth-Z8s,\n.cms-root_categoryFullWidth-c6r,\n.cms-root_productFullWidth-i6f {\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "cms-root-hFv overflow-x-hidden",
	"heading": "cms-heading-5TQ mb-2 mt-4 text-center md_mb-10",
	"root_empty": "cms-root_empty-2EE cms-root-hFv overflow-x-hidden",
	"root_1column": "cms-root_1column-8Hs cms-root-hFv overflow-x-hidden",
	"root_2columnsLeft": "cms-root_2columnsLeft-YDb cms-root-hFv overflow-x-hidden",
	"root_2columnsRight": "cms-root_2columnsRight-Q-K cms-root-hFv overflow-x-hidden",
	"root_3columns": "cms-root_3columns-yZZ cms-root-hFv overflow-x-hidden",
	"root_cmsFullWidth": "cms-root_cmsFullWidth-Z8s cms-root-hFv overflow-x-hidden",
	"root_categoryFullWidth": "cms-root_categoryFullWidth-c6r cms-root-hFv overflow-x-hidden",
	"root_productFullWidth": "cms-root_productFullWidth-i6f cms-root-hFv overflow-x-hidden"
};
export default ___CSS_LOADER_EXPORT___;
