import React, { Fragment, useEffect } from 'react';
import { shape, string } from 'prop-types';
import { useCmsPage } from '@magento/peregrine/lib/talons/Cms/useCmsPage';
import RichContent from '@magento/venia-ui/lib/components/RichContent';
import { Meta, StoreTitle } from '@magento/venia-ui/lib/components/Head';
import { fullPageLoadingIndicator } from '@magento/venia-ui/lib/components/LoadingIndicator'; // import CMSPageShimmer from '@magento/venia-ui/lib/RootComponents/CMS/cms.shimmer';

import { useStyle } from '@magento/venia-ui/lib/classify';
import { toCamelCase } from '@magento/venia-ui/lib/util/toCamelCase';
import defaultClasses from './cms.module.css';

var CMSPage = function CMSPage(props) {
  var identifier = props.identifier;
  var talonProps = useCmsPage({
    identifier: identifier
  });
  var cmsPage = talonProps.cmsPage,
      shouldShowLoadingIndicator = talonProps.shouldShowLoadingIndicator;
  var classes = useStyle(defaultClasses, props.classes);

  if (shouldShowLoadingIndicator) {
    return fullPageLoadingIndicator;
  }

  var title = cmsPage.title,
      meta_title = cmsPage.meta_title,
      meta_description = cmsPage.meta_description,
      page_layout = cmsPage.page_layout,
      content = cmsPage.content,
      meta_keywords = cmsPage.meta_keywords;
  var pageTitle = meta_title || title;
  var rootClassName = page_layout ? classes["root_".concat(toCamelCase(page_layout))] : classes.root;
  return React.createElement(Fragment, null, React.createElement(StoreTitle, null, pageTitle), React.createElement(Meta, {
    name: "title",
    content: meta_title ? meta_title : pageTitle
  }), React.createElement(Meta, {
    name: "description",
    content: meta_description
  }), React.createElement(Meta, {
    name: "keywords",
    content: meta_keywords ? meta_keywords : ''
  }), React.createElement("article", {
    className: "".concat(rootClassName)
  }, React.createElement(RichContent, {
    html: content
  })));
};

CMSPage.propTypes = {
  identifier: string,
  classes: shape({
    root: string,
    heading: string,
    root_empty: string,
    root_1column: string,
    root_2columnsLeft: string,
    root_2columnsRight: string,
    root_3columns: string,
    root_cmsFullWidth: string,
    root_categoryFullWidth: string,
    root_productFullWidth: string
  })
};
export default CMSPage;